<template>
  <b-container fluid>
      <iq-card>
         <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('tpm_report.other_allowance_report') }} {{ $t('globalTrans.search') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                    <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                              </template>
                              <v-select name="organization"
                                v-model="search.fiscal_year_id"
                                label="text"
                                :reduce="item => item.value"
                                :options= fiscalYearList
                                :placeholder="$t('globalTrans.select')"
                                :state="errors[0] ? false : (valid ? true : null)"
                              />
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1">
                        <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="org_id"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="search.org_id"
                              :options="orgList"
                              id="org_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="office_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.office_type')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.office_type_id"
                                  :options="officeTypeList"
                                  id="office_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :disabled="$store.state.Auth.activeRoleId === 1 ? false : true"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="office_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.office')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.office_id"
                                  :options="officeList"
                                  id="office_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :disabled="$store.state.Auth.activeRoleId === 1 ? false : true"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_type_id"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="search.training_type_id"
                              :options="trainingTypeList"
                              id="training_type_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Training Category"  vid="training_category_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_category_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="search.training_category_id"
                                :options="trainingCategoryList"
                                id="training_category_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Training Title"  vid="training_title_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_title_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="search.training_title_id"
                                :options="trainingTitleList"
                                id="training_title_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                          </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <!-- <b-col lg="6" sm="12">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="batch_no"
                            >
                              <template v-slot:label>
                                {{$t('elearning_iabm.batch_no')}}
                              </template>
                              <b-form-select
                                plain
                                v-model="search.batch_no"
                                :options="batchList"
                                id="batch_no"
                                >
                                <template v-slot:first>
                                    <b-form-select-option value=0>{{ circularLoading ? 'Loading....' : $t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                          </b-form-group>
                        </b-col> -->
                        <b-col lg="6" sm="12">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="circular_memo_no"
                            >
                              <template v-slot:label>
                                {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="search.circular_memo_no"
                                :options="circularList"
                                id="circular_memo_no"
                                >
                                <template v-slot:first>
                                    <b-form-select-option value=''>{{ circularLoading ? 'Loading....' : $t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                          </b-form-group>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Other Trainee Type" vid="other_trainee_type_id" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="batch_no"
                            >
                            <template v-slot:label>
                              {{$t('elearning_config.trainee_type')}} <span class="text-danger">*</span>
                            </template>
                              <b-form-select
                                plain
                                v-model="search.other_trainee_type_id"
                                :options="traineeTypeList"
                                id="other_trainee_type_id"
                                >
                                <template v-slot:first>
                                     <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                </template>
                              </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                    </b-row>
                    <div class="row">
                      <b-col sm="9">
                      </b-col>
                      <b-col sm="3" style="text-align: right;">
                        <b-button size="sm" type="submit" variant="primary" class="mt-20">
                          <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                        </b-button>
                      </b-col>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
      </iq-card>
      <b-row>
        <b-col md="12" v-if="showData">
          <b-overlay :show="loading">
            <b-row v-if="datas.length > 0">
              <b-col md="12">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">{{ $t('tpm_report.other_allowance_report') }}</h4>
                  </template>
                  <template v-slot:headerAction>
                    <b-button class="btn_add_new" @click="pdfExport">
                      <i class="fas fa-print"></i>{{ $t('globalTrans.print') }}
                    </b-button>
                    <!-- <export-excel
                      class="btn btn-success mr-2"
                      :data="excelData"
                      :title="headerValue"
                      worksheet="Report Sheet"
                      name="payment_report.xls">
                      {{ $t('globalTrans.export_excel') }}
                    </export-excel> -->
                  </template>
                  <template v-slot:body>
                    <b-overlay>
                      <b-row mt-5>
                        <b-col md="12" class="table-responsive">
                          <div style="border: 2px solid;margin:10px;padding:10px">
                            <b-row>
                              <b-col>
                                <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="16" :office-id="datas[0].office_id">
                                  <template v-slot:projectNameSlot>
                                    {{ }}
                                  </template>
                                  {{ $t('tpm_report.other_allowance_report') }}
                                </list-report-head>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <div class="text-center">
                                  <table style="width:100%;color:black;">
                                    <tr>
                                      <td align="left" style="width:10%">{{ $t('elearning_config.fiscal_year') }}</td>
                                      <td align="center" style="width:5%">:</td>
                                      <td align="left" style="width:25%" v-if="search.fiscal_year_id"><b>{{ ($i18n.locale==='bn') ? searchHeaderData.fiscal_year_bn : searchHeaderData.fiscal_year_en }}</b></td>
                                      <td align="left" style="width:50%" v-else><b>{{ $t('globalTrans.all')}}</b></td>
                                      <td align="right" style="width:12%">{{ $t('elearning_iabm.circular_memo_no') }}</td>
                                      <td align="right" style="width:5%">:</td>
                                      <td align="center" style="width:25%" v-if="search.circular_memo_no"><b>{{ ($i18n.locale==='bn') ? search.circular_memo_no : search.circular_memo_no }}</b></td>
                                      <td align="center" style="width:25%" v-else><b>{{ $t('globalTrans.all')}}</b></td>
                                    </tr>
                                    <tr>
                                      <td align="left" style="width:10%">{{ $t('elearning_config.training_title') }}</td>
                                      <td align="center" style="width:5%">:</td>
                                      <td align="left" style="width:25%" v-if="search.training_title_id"><b>{{ ($i18n.locale==='bn') ? searchHeaderData.training_title_bn : searchHeaderData.training_title_en }}</b></td>
                                      <td align="left" style="width:25%" v-else><b>{{ $t('globalTrans.all')}}</b></td>
                                      <td align="right" style="width:12%">{{ $t('elearning_config.trainee_type') }}</td>
                                      <td align="right" style="width:5%">:</td>
                                      <td align="center" style="width:25%" v-if="search.other_trainee_type_id"><b>{{ ($i18n.locale==='bn') ? searchHeaderData.other_trainee_type_bn : searchHeaderData.other_trainee_type_en }}</b></td>
                                      <td align="center" style="width:25%" v-else><b>{{ $t('globalTrans.all')}}</b></td>
                                    </tr>
                                    <tr>
                                      <td align="left" style="width:10%">{{ $t('globalTrans.office') }}</td>
                                      <td align="center" style="width:5%">:</td>
                                      <td align="left" style="width:25%" v-if="search.office_id"><b>{{ ($i18n.locale==='bn') ? searchHeaderData.office_bn : searchHeaderData.office_en }}</b></td>
                                      <td align="left" style="width:25%" v-else><b>{{ $t('globalTrans.all')}}</b></td>
                                    </tr>
                                  </table>
                                </div>
                              </b-col>
                            </b-row>
                            <b-row>
                            </b-row>
                            <b-row>
                              <b-col>
                                <b-table-simple class="tg mt-3" bordered hover small caption-top responsive v-if="datas.length > 0">
                                  <b-thead>
                                    <b-tr>
                                      <th class="text-center align-middle" style="width:5%" rowspan="2">{{$t('globalTrans.sl_no')}}</th>
                                      <th class="text-center align-middle" style="width:12%" rowspan="2">{{$t('elearning_tim.name_of_trainee')}}<br/>{{($i18n.locale == 'bn' ? 'ও' : '&')}}<br/>{{$t('admission_form.mobile_no')}}</th>
                                      <th class="text-center align-middle" style="width:12%" rowspan="2">{{$t('globalTrans.designation')}}</th>
                                      <th class="text-center align-middle" style="width:12%" rowspan="2" v-if="search.other_trainee_type_id > 1 && search.payment_type === 'Trainee'">{{$t('globalTrans.address')}}</th>
                                      <th class="text-center align-middle" style="width:12%" rowspan="2" v-if="search.other_trainee_type_id == 1 && search.payment_type === 'Trainee'">{{$t('elearning_config.organization')}}{{ '/' }}{{ $t('elearning_tim.institution') }}</th>
                                      <th class="text-center align-middle" style="width:12%" rowspan="2" v-if="search.payment_type === 'Trainer'">{{$t('elearning_config.organization')}}{{ '/' }}{{ $t('elearning_tim.institution') }}</th>
                                      <b-th class="text-center align-middle" :colspan="materials.length">{{$t('elearning_iabm.training')}} {{$t('elearning_tim.materials')}}</b-th>
                                      <th class="text-center align-middle" style="width:10%" rowspan="2">{{$t('elearning_tim.per_person_daily_payment')}}</th>
                                      <th class="text-center align-middle" style="width:10%" rowspan="2">{{ $n(days)}} {{ ($i18n.locale == 'bn' ? 'দিনের' : 'days') }} {{$t('elearning_tim.total_payment')}}</th>
                                      <th class="text-center align-middle" style="width:10%" rowspan="2">{{$t('globalTrans.signature_date_&_stamp')}}</th>
                                      <th class="text-center align-middle" style="width:10%" rowspan="2">{{$t('globalTrans.remarks')}}</th>
                                    </b-tr>
                                    <b-tr>
                                      <b-th v-for="(material, index1) in materials" :key="index1" style="vertical-align: middle" class="text-center" width="5%">
                                        {{ $i18n.locale === 'bn' ? material.name_bn : material.name }}
                                      </b-th>
                                    </b-tr>
                                  </b-thead>
                                  <b-tbody>
                                    <b-tr v-for="(item, index) in datas" :key="index">
                                        <b-td class="text-center">{{ $n(index + 1, { useGrouping: false }) }}</b-td>
                                        <b-td class="text-center">{{ ($i18n.locale === 'bn') ? item.personal.name_bn : item.personal.name }}
                                        <br/>
                                        {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(item.personal.mobile, { useGrouping: false }) }}
                                        </b-td>
                                        <b-td class="text-center">
                                          {{ (item.personal.designation || item.personal.designation !== 'null' && $i18n.locale === 'bn') ? item.personal.designation_bn : item.personal.designation  }}
                                        </b-td>
                                        <b-td  v-if="search.payment_type === 'Trainer'" class="text-center">
                                          {{ (item.personal.other_org || item.personal.other_org !== 'null' && $i18n.locale === 'bn') ? item.personal.other_org_bn : item.personal.other_org }}
                                        </b-td>
                                        <b-td  v-if="search.other_trainee_type_id == 1 && search.payment_type === 'Trainee'" class="text-center">
                                          {{ (item.personal.other_org || item.personal.other_org !== 'null' && $i18n.locale === 'bn') ? item.personal.other_org_bn : item.personal.other_org }}
                                        </b-td>
                                        <b-td v-if="search.other_trainee_type_id > 1 && search.payment_type === 'Trainee'" class="text-center">
                                          {{ (item.personal.other_address || item.personal.other_address !== 'null' && $i18n.locale === 'bn') ? item.personal.other_address : item.personal.other_address }}
                                        </b-td>
                                        <!-- <b-td v-if="item.office_id">{{ getOffice(item.office_id) }}</b-td>
                                        <b-td v-else>{{ ($i18n.locale==='bn') ? item.office_bn : item.office }}</b-td>
                                        <b-td v-if="item.org_id">{{ getOrg(item.org_id) }}</b-td>
                                        <b-td v-else>{{ ($i18n.locale==='bn') ? item.org_bn : item.org }}</b-td> -->
                                        <!-- <b-td class="text-right">{{ $n(item.total_days, { useGrouping: false }) }}</b-td> -->
                                        <b-td class="text-center" v-for="(material, index1) in materials" :key="index1">
                                          {{ $n(material.quantity) }}
                                        </b-td>
                                        <b-td class="text-right">{{ $n(item.honour_amount) + '/-' }}</b-td>
                                        <b-td class="text-right">
                                          {{$n(item.total_days * item.honour_amount) + '/-' }}
                                        </b-td>
                                        <b-td class="text-right">
                                        </b-td>
                                        <b-td class="text-right">
                                        </b-td>
                                        <!-- <b-td class="text-right">{{ $n(item.other) }}</b-td>
                                        <b-td class="text-right">{{ $n(item.vat_tax_amount) }}</b-td>
                                        <b-td class="text-right">{{ $n(item.payment_amount) }}</b-td> -->
                                      </b-tr>
                                    <!-- <span hidden>{{ $i = 1 }} {{ $si = 1 }}</span>
                                    <template v-for="(items, index) in datas">
                                      <b-tr style="background: #ddd;" :key="index">
                                        <b-td colspan="11" class="text-left"> <span class="mr-3">{{ $n($i++) + '. ' + $t('elearning_iabm.batch_no') + ' :'}}</span>{{ getBatch(index) }} </b-td>
                                      </b-tr>
                                      <b-tr v-for="(item, index2) in items" :key="index2">
                                        <b-td class="text-center">{{ $n(index2 + 1, { useGrouping: false }) }}</b-td>
                                        <b-td class="text-center">{{ ($i18n.locale==='bn') ? item.personal.name_bn : item.personal.name }}</b-td>
                                        <b-td v-if="item.designation_id">
                                          {{ getDesignation(item.designation_id) }}
                                        </b-td>
                                        <b-td v-else>
                                          {{ ($i18n.locale==='bn') ? item.designation_bn : item.designation_en }}
                                        </b-td>
                                        <b-td v-if="item.office_id">{{ getOffice(item.office_id) }}</b-td>
                                        <b-td v-else>{{ ($i18n.locale==='bn') ? item.office_bn : item.office }}</b-td>
                                        <b-td v-if="item.org_id">{{ getOrg(item.org_id) }}</b-td>
                                        <b-td v-else>{{ ($i18n.locale==='bn') ? item.org_bn : item.org }}</b-td>
                                        <b-td class="text-right">{{ $n(item.total_days, { useGrouping: false }) }}</b-td>
                                        <b-td class="text-right">{{ $n(item.honour_amount) }}</b-td>
                                        <b-td class="text-right">
                                          {{$n(item.total_days * item.honour_amount)}}
                                        </b-td>
                                        <b-td class="text-right">{{ $n(item.other) }}</b-td>
                                        <b-td class="text-right">{{ $n(item.vat_tax_amount) }}</b-td>
                                        <b-td class="text-right">{{ $n(item.payment_amount) }}</b-td>
                                      </b-tr>
                                    </template> -->
                                  </b-tbody>
                                </b-table-simple>
                              </b-col>
                            </b-row>
                          </div>
                        </b-col>
                      </b-row>
                    </b-overlay>
                  </template>
                </iq-card>
              </b-col>
            </b-row>
            <div class="panel-body text-center mt-3" v-else>
              <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
            </div>
          </b-overlay>
        </b-col>
      </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { otherBillPaymentReport, circularList } from '../../api/routes'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
  props: ['id'],
  components: {
      ListReportHead
  },
  created () {
      this.search = Object.assign({}, this.search, {
          // org_id: this.$store.state.dataFilters.orgId,
          org_id: 16,
          fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
      })
      if (this.$store.state.Auth.activeRoleId !== 1) {
        this.search.org_id = this.$store.state.Auth.authUser.org_id
        this.search.office_type_id = this.$store.state.Auth.authUser.office_type_id
        this.search.office_id = this.$store.state.Auth.authUser.office_id
        this.officeTypeList = this.getOfficeTypeOfficerList(this.$store.state.Auth.authUser.office_type_id)
      }
      this.getCircularList()
      // if (this.id) {
      //     const tmp = this.getEditingData()
      //     this.search = tmp
      // }
  },
  data () {
      return {
        saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
        search: {
          circular_memo_no: '',
          training_type_id: 0,
          training_category_id: 0,
          payment_type: 'Trainee',
          payment_status: 2,
          training_status: 2,
          training_title_id: 0,
          other_trainee_type_id: 0,
          org_id: 0,
          office_type_id: 0,
          office_id: 0,
          batch_no: 0,
          fiscal_year_id: 0
        },
        searchHeaderData: {
          fiscal_year_en: '',
          fiscal_year_bn: '',
          org_en: '',
          org_bn: '',
          circular_memo_no: '',
          training_type_en: '',
          training_type_bn: '',
          training_title_en: '',
          training_title_bn: '',
          batch_en: '',
          batch_bn: '',
          other_trainee_type_en: '',
          other_trainee_type_bn: ''
        },
        headerExcelDefault: {
          orgName: null,
          orgNameBn: null,
          address: null,
          address_bn: null
        },
        trainingCategoryList: [],
        trainingTitleList: [],
        trainingTypeList: [],
        officeTypeList: [],
        officeList: [],
        trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
        datas: [],
        circularList: [],
        allBatchDataList: [],
        traineeTypeList: [],
        otherTraineeTypeList: [],
        batchList: [],
        materials: [],
        days: '',
        loading: false,
        circularLoading: false,
        showData: false
      }
  },
  mounted () {
      core.index()
      flatpickr('.fromDate', {})
      this.AveragePriceText = (parseInt(this.search.HighestPrice) + parseInt(this.search.LowestPrice)) / 2
  },
  computed: {
    allBatchList () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
        { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
        { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
        { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
        { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
        { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
        { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
        { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
        { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
        { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
      ]
    },
    headerValue: function () {
      const headerVal = []
      if (this.$i18n.locale === 'en') {
        headerVal[0] = this.headerExcelDefault.orgName
        headerVal[1] = this.headerExcelDefault.address
        headerVal[2] = this.$t('tpm_report.allowance_report')
      } else {
        headerVal[0] = this.headerExcelDefault.orgNameBn
        headerVal[1] = this.headerExcelDefault.address_bn
        headerVal[2] = this.$t('tpm_report.allowance_report')
      }
      if (this.search.date) {
        headerVal.push(
          this.$t('globalTrans.date') + ' :  ' + this.search.date
        )
      }
      if (this.search.fiscal_year_id) {
        headerVal.push(
          (this.$i18n.locale === 'bn') ? this.$t('globalTrans.fiscal_year') + ' :  ' + this.search.fiscal_year_bn : this.$t('globalTrans.fiscal_year') + ' :  ' + this.search.fiscal_year
        )
      }
      if (this.search.org_id) {
        headerVal.push(
          (this.$i18n.locale === 'bn') ? this.$t('elearning_config.organization') + ' :  ' + this.search.org_name_bn : this.$t('elearning_config.organization') + ' :  ' + this.search.org_name
        )
      }
      if (this.search.training_type_id) {
        headerVal.push(
          (this.$i18n.locale === 'bn') ? this.$t('elearning_config.training_type') + ' :  ' + this.search.training_type_bn : this.$t('elearning_config.training_type') + ' :  ' + this.search.training_type
        )
      }
      if (this.search.training_category_id) {
        headerVal.push(
          (this.$i18n.locale === 'bn') ? this.$t('elearning_config.training_category') + ' :  ' + this.search.training_category_bn : this.$t('elearning_config.training_category') + ' :  ' + this.search.training_category
        )
      }
      if (this.search.training_title_id) {
        headerVal.push(
          (this.$i18n.locale === 'bn') ? this.$t('elearning_config.training_title') + ' :  ' + this.search.training_title : this.$t('elearning_config.training_title') + ' :  ' + this.search.training_title
        )
      }
      return headerVal
    },
    // excelData: function () {
    //   const listData = this.datas
    //   var serial = 0
    //   const listContractor = listData.map(keyItem => {
    //     serial++
    //       if (this.$i18n.locale === 'en') {
    //           return {
    //             sl: serial,
    //             Type: keyItem.payment_type,
    //             Name: keyItem.personal.name,
    //             'Honour Amount': keyItem.honour_amount,
    //             'Vat/Tax (%)': keyItem.vat_tax,
    //             Other: keyItem.other,
    //             Detucton: keyItem.detucton,
    //             'Payment Amount': keyItem.payment_amount
    //           }
    //       } else {
    //         return {
    //           'ক্রমিক সংখ্যা': this.$n(serial),
    //           টাইপ: keyItem.payment_type,
    //           নাম: keyItem.personal.name,
    //           'সম্মানের পরিমাণ': this.$n(keyItem.honour_amount),
    //           'মুল্য সংযোজন কর (%)': this.$n(keyItem.vat_tax),
    //           অন্যান্য: this.$n(keyItem.other),
    //           বিচ্ছিন্ন: this.$n(keyItem.detucton),
    //           'পরিশোধিত অর্থ': this.$n(keyItem.payment_amount)
    //         }
    //       }
    //   })
    //   return listContractor
    // },
    // trainingTypeList: function () {
    //   return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    // },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    trainerEvaluationList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
    },
    timePreiodList: function () {
      return this.$store.state.agriMarketing.commonObj.timePreiodList
    },
    i18 () {
      return this.$i18n.locale
    }
  },
  watch: {
    // 'search.circular_memo_no': function (newValue) {
    //       this.getCircularMemoNo(newValue)
    //   },
    // 'search.training_type_id': function (newValue) {
    //   this.trainingCategoryList = this.getCategoryList(newValue)
    // },
    // 'search.training_category_id': function (newValue) {
    //   this.trainingTitleList = this.getTrainingTitleList(newValue)
    // }
    'search.org_id': function (newValue, oldValue) {
      this.trainingTypeList = this.getTypeList()
      if (newValue !== oldValue) {
        if (this.$store.state.Auth.activeRoleId === 1) {
          this.officeTypeList = this.getOfficeTypeList(newValue)
        }
      }
    },
    'search.office_type_id': function (newValue) {
      this.officeList = this.getOfficeList(newValue)
    },
    'search.training_type_id': function (newValue) {
      this.trainingCategoryList = this.getCategoryList(newValue)
    },
    'search.training_category_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    },
    'search.training_title_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getCircularList()
      }
    },
    'search.circular_memo_no': function (newVal, oldVal) {
        this.getOtherTraineeType()
    }
  },
  methods: {
    getOfficeName (id) {
        const office = this.$store.state.commonObj.officeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return office !== undefined ? office.text_bn : ''
        } else {
            return office !== undefined ? office.text_en : ''
        }
    },
    async getOtherTraineeType () {
      this.circularLoading = true
      const serchData = {
        org_id: this.search.org_id,
        fiscal_year_id: this.search.fiscal_year_id,
        office_id: this.search.office_id,
        office_type_id: this.search.office_type_id,
        circular_memo_no: this.search.circular_memo_no
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, '/iabm/circular-publications/other-trainee-type' + '/' + 'iab_circular_publications', serchData)
      if (!result.success) {
        this.otherTraineeTypeList = []
      } else {
        this.otherTraineeTypeList = result.otherTraineeTypeList
        this.getTraineeType()
      }
      this.circularLoading = false
    },
    getTraineeType () {
      const typeData = this.otherTraineeTypeList.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
            return { value: obj.id, text: obj.other_trainee_type_bn }
          } else {
            return { value: obj.id, text: obj.other_trainee_type }
          }
        })
        if (typeData) {
          this.traineeTypeList = typeData
        } else {
          this.traineeTypeList = []
        }
    },
    getOfficeTypeOfficerList (officeTypeId) {
      const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return OfficeTypeList.filter(item => item.value === officeTypeId)
      }
      return OfficeTypeList
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return OfficeTypeList.filter(item => item.org_id === orgId)
      }
      return OfficeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getBatch (id) {
      const sectorObj = this.allBatchList.find(el => el.value === parseInt(id))
      if (sectorObj !== undefined) {
        return this.$i18n.locale === 'bn' ? sectorObj.text_bn : sectorObj.text_en
      }
    },
    getOffice (id) {
      const sectorObj = this.$store.state.commonObj.officeList.find(el => el.value === parseInt(id))
      if (sectorObj !== undefined) {
        return this.$i18n.locale === 'bn' ? sectorObj.text_bn : sectorObj.text_en
      }
    },
    getDesignation (id) {
      const sectorObj = this.$store.state.commonObj.designationList.find(el => el.value === parseInt(id))
      if (sectorObj !== undefined) {
        return this.$i18n.locale === 'bn' ? sectorObj.text_bn : sectorObj.text_en
      }
    },
    getOrg (id) {
      const sectorObj = this.$store.state.commonObj.organizationProfileList.find(el => el.value === parseInt(id))
      if (sectorObj !== undefined) {
        return this.$i18n.locale === 'bn' ? sectorObj.text_bn : sectorObj.text_en
      }
    },
    getTypeList () {
      if (this.search.org_id) {
        const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
        if (this.search.org_id) {
          return type.filter(item => item.org_id === parseInt(this.search.org_id))
        }
        return type
      }
    },
    batchInfo () {
      const batchData = this.allBatchListData.filter(allBatchList => parseInt(allBatchList.training_title_id) === parseInt(this.search.training_title_id))
      const batchList = []
      batchData.map((obj, index) => {
        if (obj.batch_no) {
          const isThere = this.allBatchList.find(allBatchList => parseInt(allBatchList.value) === parseInt(obj.batch_no))
          if (typeof isThere !== 'undefined') {
            batchList.push(isThere)
          }
        }
      })
      this.batchList = batchList
      if (this.batchList.length === 1) {
        this.search.batch_no = 1
      }
    },
    circluarList () {
      const listObject = this.allBatchListData.filter(allBatchListData => parseInt(allBatchListData.training_title_id) === parseInt(this.search.training_title_id))
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        } else {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        }
      })
      this.circularList = tmpList
    },
    async getCircularList () {
      this.circularLoading = true
      const serchData = {
        fiscal_year_id: this.search.fiscal_year_id,
        training_type_id: this.search.training_type_id,
        training_category_id: this.search.training_category_id,
        training_title_id: this.search.training_title_id,
        office_id: this.search.office_id
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularList + '/' + 'iab_circular_publications', serchData)
      if (!result.success) {
      } else {
        this.allBatchListData = result.batchList
        // this.batchInfo()
        this.circluarList()
      }
      this.circularLoading = false
    },
      // async getCircularList () {
      //     this.circularLoading = true
      //     this.circularLoading = true
      //     const serchData = {
      //         org_id: this.$store.state.dataFilters.orgId
      //     }
      //     const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublicationList + '/' + 'tpm_training_calendar' + '/' + 1, serchData)
      //     if (!result.success) {
      //     } else {
      //         const listObject = result.data
      //         const tmpList = listObject.map((obj, index) => {
      //         if (this.$i18n.locale === 'bn') {
      //             return { value: obj.circular_memo_no, text: obj.circular_memo_no }
      //         } else {
      //             return { value: obj.circular_memo_no, text: obj.circular_memo_no }
      //         }
      //         })
      //         this.circularList = tmpList
      //     }
      //     this.circularLoading = false
      // },
      // async getCircularMemoNo (getCircularMemoNo) {
      //     if (getCircularMemoNo) {
      //         this.loading = true
      //         const params = {
      //           circular_memo_no: getCircularMemoNo,
      //           table: 'tpm_training_calendar'
      //         }
      //         const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublication, params)
      //         if (!result.success) {
      //         } else {
      //             this.circularErrorMsg = false
      //             const trData = result.data
      //             this.search.training_title_id = trData.training_title_id
      //             this.search.training_type_id = trData.training_type_id
      //             this.search.training_category_id = trData.training_category_id
      //         }
      //         this.loading = false
      //     }
      // },
    getCategoryList (typeId) {
      const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
      if (typeId) {
        return trainingCategoryList.filter(item => item.training_type_id === typeId)
      }
      return trainingCategoryList
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_category_id === categoryId)
      }
      return trainingTitleList
    },
    pdfExport () {
        const reportTitle = this.$t('tpm_report.other_allowance_report')
        ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this, this.datas, this.search, this.days)
    },
    onChangeFile (e) {
        this.attachmentDemo = e.target.files[0]
    },
    getEditingData () {
        const tmpData = this.$store.state.list.find(item => item.id === this.id)
        return JSON.parse(JSON.stringify(tmpData))
    },
    async searchData () {
      // RestApi.getData(trainingElearningServiceBaseUrl, '/config/report-head/detail/12').then(response => {
      //   if (response.success) {
      //     const orgList = this.$store.state.commonObj.organizationProfileList.find(item => item.value === 3)
      //     const orgName = typeof orgList !== 'undefined' ? orgList.text_en : ''
      //     const orgNameBn = typeof orgList !== 'undefined' ? orgList.text_bn : ''
      //     this.headerExcelDefault.orgName = orgName
      //     this.headerExcelDefault.orgNameBn = orgNameBn
      //     this.headerExcelDefault.address = response.data.address
      //     this.headerExcelDefault.address_bn = response.data.address_bn
      //   }
      // })
      this.loading = true
      this.showData = true
      if (this.search.fiscal_year_id) {
        const fiscalYear = this.fiscalYearList.find(obj => obj.value === this.search.fiscal_year_id)
        const customItem = {
          fiscal_year_en: fiscalYear?.text_en,
          fiscal_year_bn: fiscalYear?.text_bn
        }
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, customItem)
      }
      if (this.search.circular_memo_no) {
        const circularObj = this.circularList.find(obj => obj.value === this.search.circular_memo_no)
        const circularData = {
          circular_memo_no_en: circularObj?.text,
          circular_memo_no_bn: circularObj?.text
        }
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, circularData)
      }
      if (this.search.training_title_id) {
        const titleObj = this.trainingTitleList.find(obj => obj.value === this.search.training_title_id)
        const titleData = {
          training_title_en: titleObj?.text_en,
          training_title_bn: titleObj?.text_bn
        }
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, titleData)
      }
      if (this.search.other_trainee_type_id) {
        const otherTraineeTypeObj = this.$store.state.TrainingElearning.commonObj.otherTraineeTypeList.find(obj => obj.value === this.search.other_trainee_type_id && obj.status === 1)
        const otherTraineeTypeData = {
          other_trainee_type_en: otherTraineeTypeObj?.text_en,
          other_trainee_type_bn: otherTraineeTypeObj?.text_bn
        }
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, otherTraineeTypeData)
      }
      if (this.search.office_id) {
        const officeObj = this.$store.state.commonObj.officeList.find(obj => obj.value === this.search.office_id)
        const officeData = {
          office_en: officeObj?.text_en,
          office_bn: officeObj?.text_bn
        }
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, officeData)
      }
      // if (this.search.batch_no) {
      //   const batchObj = this.batchList.find(obj => obj.value === this.search.batch_no)
      //   const batchData = {
      //     batch_en: batchObj?.text_en,
      //     batch_bn: batchObj?.text_bn
      //   }
      //   this.searchHeaderData = Object.assign({}, this.searchHeaderData, batchData)
      // }
      // const orgObj = this.$store.state.commonObj.organizationProfileList.find(item => item.value === this.search.org_id)
      // this.search.org_name = typeof orgObj !== 'undefined' ? orgObj.text_en : ''
      // this.search.org_name_bn = typeof orgObj !== 'undefined' ? orgObj.text_bn : ''

      // const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(item => item.value === this.search.fiscal_year_id)
      // this.search.fiscal_year = typeof fiscalYearObj !== 'undefined' ? fiscalYearObj.text_en : ''
      // this.search.fiscal_year_bn = typeof fiscalYearObj !== 'undefined' ? fiscalYearObj.text_bn : ''

      // const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(item => item.value === this.search.training_type_id)
      // this.search.training_type = typeof trainingTypeObj !== 'undefined' ? trainingTypeObj.text_en : ''
      // this.search.training_type_bn = typeof trainingTypeObj !== 'undefined' ? trainingTypeObj.text_bn : ''

      // const trainingCategoryListObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(item => item.value === this.search.training_category_id)
      // this.search.training_category = typeof trainingCategoryListObj !== 'undefined' ? trainingCategoryListObj.text_en : ''
      // this.search.training_category_bn = typeof trainingCategoryListObj !== 'undefined' ? trainingCategoryListObj.text_bn : ''

      // const trainingTitleListObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(item => item.value === this.search.training_title_id)
      // this.search.training_title = typeof trainingTitleListObj !== 'undefined' ? trainingTitleListObj.text_en : ''
      // this.search.training_title_bn = typeof trainingTitleListObj !== 'undefined' ? trainingTitleListObj.text_bn : ''

      const result = await RestApi.getData(trainingElearningServiceBaseUrl, otherBillPaymentReport, this.search)
      if (result.success) {
        this.loading = false
        if (result.data) {
          this.datas = result.data
          this.days = this.datas[0].total_days
          this.materials = result.material
        }
      } else {
        this.datas = []
        this.loading = false
      }
    }
    // getCustomDataList (data) {
    //   const listData = data.map(item => {
    //     const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.professional_org_id))
    //     const orgData = {}
    //     if (typeof orgObj !== 'undefined') {
    //       orgData.org = orgObj.text_en
    //       orgData.org_bn = orgObj.text_bn
    //     } else {
    //       orgData.org = ''
    //       orgData.org_bn = ''
    //     }

    //     const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.professional_office_id))
    //     const officeData = {}
    //     if (typeof officeObj !== 'undefined') {
    //       officeData.office = officeObj.text_en
    //       officeData.office_bn = officeObj.text_bn
    //     } else {
    //       officeData.office = ''
    //       officeData.office_bn = ''
    //     }

    //     const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
    //     const fiscalYearData = {}
    //     if (typeof fiscalYearObj !== 'undefined') {
    //       fiscalYearData.fiscal_year = fiscalYearObj.text_en
    //       fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
    //     } else {
    //       fiscalYearData.fiscal_year = ''
    //       fiscalYearData.fiscal_year_bn = ''
    //     }
    //     const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
    //     const trainingTitleData = {}
    //     if (typeof trainingTitleObj !== 'undefined') {
    //       trainingTitleData.training_title = trainingTitleObj.text_en
    //       trainingTitleData.training_title_bn = trainingTitleObj.text_bn
    //     } else {
    //       trainingTitleData.training_title = ''
    //       trainingTitleData.training_title_bn = ''
    //     }
    //     const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
    //     const OfficeTypeData = {}
    //     if (typeof OfficeTypeListObj !== 'undefined') {
    //       OfficeTypeData.office_type = OfficeTypeListObj.text_en
    //       OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
    //     } else {
    //       OfficeTypeData.office_type = ''
    //       OfficeTypeData.office_type_bn = ''
    //     }
    //     const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
    //     const trainingTypeData = {}
    //     if (typeof trainingTypeObj !== 'undefined') {
    //       trainingTypeData.training_type = trainingTypeObj.text_en
    //       trainingTypeData.training_type_bn = trainingTypeObj.text_bn
    //     } else {
    //       trainingTypeData.training_type = ''
    //       trainingTypeData.training_type_bn = ''
    //     }
    //     const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
    //     const trainingCategoryData = {}
    //     if (typeof trainingCategoryObj !== 'undefined') {
    //       trainingCategoryData.training_category = trainingCategoryObj.text_en
    //       trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
    //     } else {
    //       trainingCategoryData.training_category = ''
    //       trainingCategoryData.training_category_bn = ''
    //     }
    //     const desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
    //     const desigData = {}
    //     if (typeof desigObj !== 'undefined') {
    //       desigData.designation_name = desigObj.text_en
    //       desigData.designation_name_bn = desigObj.text_bn
    //     } else {
    //       desigData.designation_name = ''
    //       desigData.designation_name_bn = ''
    //     }
    //     return Object.assign({}, item, orgData, officeData, fiscalYearData, trainingTitleData, OfficeTypeData, trainingTypeData, trainingCategoryData, desigData)
    //   })
    //   return listData
    // }
  }
}
</script>
